/* bg module: theme */

.relative {
  position: relative;
}
.bg-gray {
  background-color: $bg-gray;
}
.bg-white {
  background-color: $color-white;
}

.box {
  &-align {
    &-vertically {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
    }
    &-height {
      height: auto;
    }
  }
  &-bg {
    background-color: $bg-blue;
  }
  &-quote {
    width: 120px;
    height: 120px;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    @include media-tablet-only {
      width: 80px;
      height: 80px;
      top: -3rem;
      svg {
        width: 2.5rem;
      }
    }
  }
}

.border {
  &-tl {
    box-shadow: -30px -30px 0 0 $bg-blue;
  }
  &-tr {
    box-shadow: 30px -30px 0 0 $bg-blue;
    &-extra {
      box-shadow: 8rem -30px 0 0 $bg-blue;
      @include media-desktop-only {
        box-shadow: 16rem -30px 0 0 $bg-blue;
      }
    }
  }
  &-bl {
    box-shadow: -30px 30px 0 0 $bg-blue;
  }
  &-br {
    box-shadow: 30px 30px 0 0 $bg-blue;
  }
}

.bar {
  background-color: $color-black;
  width: 40%;
  height: 2px;
  &.inverted {
    background-color: $color-white;
  }
  &-center {
    margin: 1rem auto 0;
  }
  &-left {
    margin: 1rem auto 0 0;
  }
  &-right {
    margin: 1rem 0 0 auto;
  }
}