.diagram {
	position: relative;
	
	// &.fue {
		// background-image: url(/wp-content/themes/georgiahairinstitute_com/images/fue-diagram-desktop.webp);
		// background-position: center;
		// background-repeat: no-repeat;
		// width: 100%;
		// height: 2092px;

		// @include media-laptop-only {
		// 	background-image: url(/wp-content/themes/georgiahairinstitute_com/images/fue-diagram-laptop.webp);
		// 	height: 2082px;
		// }

		// @include media-mobile-only {
		// 	background-image: url(/wp-content/themes/georgiahairinstitute_com/images/fue-diagram-mobile.webp);
		// 	height: 2095px;
		// }
		
	// }

	// &.hair-restoration {
	// 	background-image: url(/wp-content/themes/georgiahairinstitute_com/images/diagram-hair-restoration-desktop.webp);
	// 	background-position: center;
	// 	background-repeat: no-repeat;
	// 	width: 100%;
	// 	height: 1220px;

	// 	@include media-mobile-only {
	// 		background-image: url(/wp-content/themes/georgiahairinstitute_com/images/diagram-hair-restoration-mobile.webp);
	// 		height: 1273px;
	// 	}

	// }

	.btn {
		position: absolute;
		bottom: 40px;
		left: 0;
		right: 0;
		margin: 0 auto;

		@include media-laptop-only {
			bottom: 100px;
		}

		@include media-mobile-only {
			left: 15px;
			right: 15px;
		}
	}
	
}