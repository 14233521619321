.riser {
	@media (max-width: 1330px) {
		.grid-2-col-5-7 {
			grid-template-columns: 3fr 8fr;
		}
	}

 	@include media-mobile-only {
		.grid-2-col-5-7 {
			grid-template-columns: 1fr;
		}
	}

	.bg-img {
		background-repeat: no-repeat;
	    background-size: cover;
	    background-position: 100% 50%;
	    width: 50%;
	    height: 100%;
	    position: absolute;
	    width: 60%;

	    @include media-mobile-only {
	    	display: none;
	    }
	}

	.bg-img-mobile {
		display: none;

		@include media-mobile-only {
			display: block;
		}
	}

	.galitzin-bg {
		background-image: url(/wp-content/uploads/2023/03/dr-galitzin-lg.webp);
	}

	.artas-bg {
		background-image: url(/wp-content/uploads/2023/03/artas-device.webp);
	}

	h2 {
		margin-bottom: 1rem;
	}

	h3 {
		&.subheader {
			font-family: $body-font;
			font-weight: 400;
			font-size: 2rem;
		}
	}

	.platform {
		background-color: #fff;
	    background-image: url(/wp-content/themes/georgiahairinstitute_com/images/blue-bar.svg);
	    background-position-x: -1.15rem;
	    background-repeat: repeat-y;
	    box-shadow: -15px 15px 20px 15px rgb(66 66 66 / 19%);
	    max-width: 865px;
	    padding: 6rem 6rem 6rem 10.5rem;
	    position: relative;

	    @include media-mobile-only {
	    	padding: 1rem 1rem 1rem 3rem;
	    	background-image: none;
	    	box-shadow: none;
	   	}
	}
}
