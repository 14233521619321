blockquote {
	&.quote:before {
		background: none;
	}
	&.quote {
		font-family: $heading-font;
		color: $color-white;
		font-weight: 500;
		font-size: 2.5rem;
		line-height: 3rem;
		margin-bottom: 2rem;

		@include media-mobile-only {
			font-size: 1.5rem;
			line-height: 1.75rem;
			margin: 0 0 1rem 0;
		}
	}
}

.blockquote {
	&.title {
		font-size: 2rem;
		font-weight: 400;

		@include media-mobile-only {
			font-size: 1.25rem;
			font-weight: 400;			
		}
	}
}