body,
input,
textarea,
button,
select,
.typography-body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.125rem;
  font-weight: 400;
  letter-spacing: 0.01em;
  line-height: 1.6666666667;
  font-family: franklin-gothic-urw, sans-serif;
  color: #000000;
}
@media screen and (max-width: 767px) {
  body,
  input,
  textarea,
  button,
  select,
  .typography-body {
    line-height: 1.33;
  }
}

h1,
h2,
.typography-heading {
  font-size: 2.25rem;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 1.6666666667;
  font-family: trajan-pro-3, serif;
  color: #2ca8b3;
  text-transform: capitalize;
}
@media screen and (max-width: 767px) {
  h1,
  h2,
  .typography-heading {
    font-size: 2rem;
  }
}
@media screen and (max-width: 1024px) {
  h1,
  h2,
  .typography-heading {
    line-height: 1;
  }
}

h2 span {
  display: block;
  text-transform: uppercase;
}

h3,
.typography-subheading {
  font-size: 1.9375rem;
  font-weight: 400;
  letter-spacing: 0.08em;
  line-height: 1.1612903226;
  font-family: trajan-pro-3, serif;
  letter-spacing: 0;
  text-transform: capitalize;
}
@media screen and (max-width: 767px) {
  h3,
  .typography-subheading {
    line-height: 1.25;
  }
}

.typography-subheading-lg {
  font-size: 1.625rem;
  font-weight: 500;
  letter-spacing: 0.5rem;
  line-height: 1.875rem;
  text-transform: uppercase;
  margin-bottom: 0.75rem;
}

.typography-body-jumbo,
.accordion__content {
  font-size: 1.9375rem;
}

.typography-script {
  font-family: "Twister", sans-serif;
  font-size: 3.625rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 2.375rem;
  text-transform: lowercase;
  color: #2ca8b3;
}

.typography-regular-case {
  text-transform: none;
}

.typography-heading-lg {
  font-size: 2.25rem;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 1.6666666667;
  font-family: trajan-pro-3, serif;
  color: #2ca8b3;
  text-transform: capitalize;
  font-size: 4.375rem;
}
@media screen and (max-width: 767px) {
  .typography-heading-lg {
    font-size: 2rem;
  }
}
@media screen and (max-width: 1024px) {
  .typography-heading-lg {
    line-height: 1;
  }
}

p a,
li a,
td a {
  font-weight: 500;
}

.typography-paragraph-lg {
  font-size: 1.5625rem;
  line-height: 1.5;
}

strong,
b,
th,
strong a,
.typography-heavy {
  font-weight: 700;
}

small,
.typography-fine-print {
  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: 1.25rem;
}
@media screen and (max-width: 767px) {
  small,
  .typography-fine-print {
    font-size: 1rem;
  }
}

.typography-heading-container,
.typography-heading-container-with-script {
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: max-content;
  padding-bottom: 0.5rem;
}
.typography-heading-container h3,
.typography-heading-container-with-script h3 {
  order: -1;
  margin-top: -0.15625rem;
  margin-bottom: -0.15625rem;
}
.typography-heading-container h2,
.typography-heading-container-with-script h2 {
  margin-top: 0 !important;
  position: relative;
  z-index: 2;
}
.typography-heading-container h4,
.typography-heading-container-with-script h4 {
  font-family: "Twister", sans-serif;
  font-size: 3.625rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 2.375rem;
  text-transform: lowercase;
  color: #2ca8b3;
  position: relative;
  width: 100%;
  text-align: right;
  z-index: 1;
  margin: -1rem 0 0 0;
}
.typography-heading-container + p.typography-heavy,
.typography-heading-container-with-script + p.typography-heavy {
  padding-right: 4.375rem;
}
@media screen and (max-width: 767px) {
  .typography-heading-container + p.typography-heavy,
  .typography-heading-container-with-script + p.typography-heavy {
    padding-right: 0;
  }
}

@media screen and (min-width: 768px) {
  .typography-heading-container-with-script {
    width: 120%;
  }
  .typography-heading-container-with-script h3,
  .typography-heading-container-with-script h2 {
    padding-right: 4.375rem;
  }
}

.text-align-center .typography-heading-container {
  margin: auto;
}
.text-align-center .typography-heading-container h2 {
  width: auto;
}

.color-title-light {
  color: #E3FEFF;
}

.title {
  font-family: franklin-gothic-urw, sans-serif;
  color: #000000;
  font-size: 1.75rem;
  font-weight: 300;
  margin: 0.2rem 0 0.5rem;
}
@media screen and (min-width: 768px) {
  .title {
    font-size: 1.9375rem;
  }
}
@media screen and (min-width: 1025px) {
  .title {
    margin: -1.2rem 0 0.5rem;
  }
}

.uppercase {
  text-transform: uppercase;
}

@font-face {
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
/* align module: core */
.align-children-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.align-center {
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.align-right {
  text-align: right;
}

.align-left {
  text-align: left;
}

.align-bottom {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.text-align-center {
  text-align: center;
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

/* base module: core */
html,
body {
  width: 100%;
  height: 100%;
  background: #ffffff;
}

html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
}

body,
figure {
  margin: 0;
}

header,
section,
footer {
  position: relative;
}

a {
  color: #2ca8b3;
  text-decoration: none;
  transition: color 0.3s ease-in;
}
a:hover {
  color: #36747e;
  text-decoration: none;
}

img {
  display: block;
  max-width: 100%;
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center center;
}

p:first-child,
ul:first-child,
ol:first-child,
h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child,
hr:first-child,
a:first-child,
img:first-child,
code:first-child,
pre:first-child,
table:first-child,
blockquote:first-child,
table:first-child,
dl:first-child {
  margin-top: 0;
}
p:last-child,
ul:last-child,
ol:last-child,
h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child,
hr:last-child,
a:last-child,
img:last-child,
code:last-child,
pre:last-child,
table:last-child,
blockquote:last-child,
table:last-child,
dl:last-child {
  margin-bottom: 0;
}

button,
input[type=button],
input[type=submit] {
  display: block;
  appearance: none;
  border: none;
  cursor: pointer;
}

nav ul {
  list-style: none;
  padding: 0;
}

:is(a, button, input, textarea, select, [role=button]):focus, :is(a, button, input, textarea, select, [role=button]):focus-visible {
  outline: 0.125rem solid #000000;
  outline-offset: 0.125rem;
}
:is(a, button, input, textarea, select, [role=button]):focus:not(:focus-visible) {
  outline: none;
}

.header :is(a, button, input, textarea, select, [role=button]):focus, .header :is(a, button, input, textarea, select, [role=button]):focus-visible,
.banner :is(a, button, input, textarea, select, [role=button]):focus,
.banner :is(a, button, input, textarea, select, [role=button]):focus-visible,
.inverted :is(a, button, input, textarea, select, [role=button]):focus,
.inverted :is(a, button, input, textarea, select, [role=button]):focus-visible {
  outline-color: #ffffff;
}

address {
  font-style: normal;
}

/* bg module: core */
.bg-black {
  background-color: #000000;
}

.bg-white {
  background-color: #ffffff;
}

/* container module: core */
.container {
  position: relative;
}

/* gap module: core */
.gutter-16 {
  gap: 1rem;
}

.gutter-sm,
.gutter-24 {
  gap: 1.5rem;
}

.gutter-30 {
  gap: 1.875rem;
}

@media screen and (max-width: 767px) {
  .gutter-sm,
  .gutter-24,
  .gutter-30 {
    gap: 1rem;
  }
}

.gutter-col-width {
  gap: 3.9583333333vw;
}
@media screen and (min-width: 1921px) {
  .gutter-col-width {
    gap: 4.75rem;
  }
}

/* columns module: core */
.grid,
[class^=grid-],
[class*=" grid-"] {
  display: grid;
}

.grid-2-col {
  grid-template-columns: 1fr 1fr;
}

.grid-2-col-2-8 {
  grid-template-columns: 2fr 8fr;
}

.grid-2-col-5-7 {
  grid-template-columns: 5fr 7fr;
}

.grid-2-col-7-5 {
  grid-template-columns: 7fr 5fr;
}

.grid-2-col-8-3 {
  grid-template-columns: 8fr 3fr;
}

.grid-2-col-3-8 {
  grid-template-columns: 3fr 8fr;
}

.grid-3-col {
  grid-template-columns: 1fr 1fr 1fr;
}

.grid-3-col-2-3-4 {
  grid-template-columns: 2fr 3fr 4fr;
}

.grid-3-col-2-4-3 {
  grid-template-columns: 2fr 4fr 3fr;
}

.grid-3-col-4-3-2 {
  grid-template-columns: 4fr 3fr 2fr;
}

.grid-3-col-2-3-2 {
  grid-template-columns: 2fr 3fr 2fr;
}

.grid-4-col {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
@media screen and (max-width: 1024px) {
  .grid-4-col {
    grid-template-columns: 1fr 1fr;
  }
}

.grid-5-col {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

@media screen and (max-width: 767px) {
  .grid-2-col,
  .grid-2-col-2-8,
  .grid-2-col-5-7,
  .grid-2-col-7-5,
  .grid-2-col-8-3,
  .grid-2-col-3-8,
  .grid-3-col,
  .grid-3-col-2-3-4,
  .grid-3-col-2-4-3,
  .grid-3-col-4-3-2,
  .grid-3-col-2-3-2,
  .grid-4-col,
  .grid-5-col {
    grid-template-columns: 1fr;
  }
}
.grid-no-stretch {
  align-items: flex-start;
}

.grid-vertically-center-children > * {
  align-self: center;
  justify-self: center;
}

.flex {
  display: flex;
}
@media screen and (max-width: 767px) {
  .flex {
    flex-direction: column;
  }
}

.grid-3-col-gap {
  column-gap: 4rem;
}

/* inverted module: core */
.inverted {
  color: #ffffff;
}
.inverted a {
  color: #ffffff;
  border-color: #ffffff;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.inverted a:hover {
  color: #ffffff;
  border-bottom: 1px solid transparent;
}
.inverted a.flipped {
  color: #ffffff;
  border-color: #ffffff;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.inverted a.flipped:hover {
  color: #2ca8b3;
  border-bottom: none;
}
.inverted nav a,
.inverted .nav a {
  color: #ffffff;
}
.inverted nav a:hover,
.inverted .nav a:hover {
  color: #2ca8b3;
}
.inverted .nav-vertical li::before {
  background-color: #ffffff !important;
}
.inverted .btn {
  border-bottom: none;
}

.margin-bottom-none {
  margin-bottom: 0;
}

.margin-top-none {
  margin-top: 0;
}

/* padding module: core */
.padding-sm {
  padding: 2.0833333333vw;
}
@media screen and (max-width: 767px) {
  .padding-sm {
    padding: 24px;
  }
}
@media screen and (min-width: 1921px) {
  .padding-sm {
    padding: 2.5rem;
  }
}

.padding-md {
  padding: 3.125vw;
}
@media screen and (max-width: 767px) {
  .padding-md {
    padding: 24px;
  }
}
@media screen and (min-width: 1921px) {
  .padding-md {
    padding: 3.75rem;
  }
}

.padding-lg {
  padding: 4.1666666667vw;
}
@media screen and (min-width: 1921px) {
  .padding-lg {
    padding: 5rem;
  }
}

.padding-xl {
  padding: 7.2916666667vw;
}
@media screen and (min-width: 1921px) {
  .padding-xl {
    padding: 8.75rem;
  }
}

.padding-xxl {
  padding: 10.4166666667vw;
}
@media screen and (min-width: 1921px) {
  .padding-xxl {
    padding: 12.5rem;
  }
}

.padding-top-md {
  padding-top: 2.6041666667vw;
}
@media screen and (max-width: 767px) {
  .padding-top-md {
    padding-top: 24px;
  }
}
@media screen and (min-width: 1921px) {
  .padding-top-md {
    padding-top: 3.125rem;
  }
}

.padding-bottom-md {
  padding-bottom: 2.6041666667vw;
}
@media screen and (max-width: 767px) {
  .padding-bottom-md {
    padding-bottom: 24px;
  }
}
@media screen and (min-width: 1921px) {
  .padding-bottom-md {
    padding-bottom: 3.125rem;
  }
}

.padding-h-lg {
  padding-left: 4.1666666667vw;
  padding-right: 4.1666666667vw;
}
@media screen and (min-width: 1921px) {
  .padding-h-lg {
    padding-left: 5rem;
    padding-right: 5rem;
  }
}

.padding-v-xl {
  padding-top: 7.2916666667vw;
  padding-bottom: 7.2916666667vw;
}
@media screen and (min-width: 1921px) {
  .padding-v-xl {
    padding-top: 8.75rem;
    padding-bottom: 8.75rem;
  }
}

.padding-top-xl {
  padding-top: 7.2916666667vw;
}
@media screen and (min-width: 1921px) {
  .padding-top-xl {
    padding-top: 8.75rem;
  }
}

.padding-bottom-none {
  padding-bottom: 0 !important;
}

.padding-top-none {
  padding-top: 0 !important;
}

.section {
  width: 100%;
}
.section > * {
  margin: auto;
}
.section > :not([class^=width-]):not([class*=" width-"]) {
  max-width: 1640px;
}

/* sr-only module: core */
.sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

@media screen and (min-width: 768px) {
  .sr-and-mobile-only {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important;
  }
}
@media screen and (min-width: 1201px) {
  .sr-and-above-tablet {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important;
  }
}
@media screen and (min-width: 1441px) {
  .sr-and-above-laptop {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important;
  }
}
/* svg module: core */
#svg {
  top: 0;
  left: 0;
  position: absolute;
  height: 0;
  width: 0;
  visibility: hidden;
  overflow: hidden;
  z-index: -1;
}

/* width module: core */
[class^=width-],
[class*=" width-"] {
  margin-left: auto;
  margin-right: auto;
}

.width-md {
  max-width: 33.75rem;
}

.width-xl {
  max-width: 83rem;
}

.width-full {
  max-width: 100%;
}

.width-content {
  max-width: max-content !important;
}

/* base module: theme */
p,
h4,
h5,
h6,
blockquote,
ul,
ol,
table {
  margin-top: 1.875rem;
  margin-bottom: 1.875rem;
}
p + .button, p + .button-icon,
h4 + .button,
h4 + .button-icon,
h5 + .button,
h5 + .button-icon,
h6 + .button,
h6 + .button-icon,
blockquote + .button,
blockquote + .button-icon,
ul + .button,
ul + .button-icon,
ol + .button,
ol + .button-icon,
table + .button,
table + .button-icon {
  margin-top: 2.49375rem;
}
@media screen and (max-width: 767px) {
  p + .button, p + .button-icon,
  h4 + .button,
  h4 + .button-icon,
  h5 + .button,
  h5 + .button-icon,
  h6 + .button,
  h6 + .button-icon,
  blockquote + .button,
  blockquote + .button-icon,
  ul + .button,
  ul + .button-icon,
  ol + .button,
  ol + .button-icon,
  table + .button,
  table + .button-icon {
    margin-top: 1.875rem;
  }
}
@media screen and (max-width: 767px) {
  p,
  h4,
  h5,
  h6,
  blockquote,
  ul,
  ol,
  table {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
}

h2,
.typography-heading-container:not(:first-child) {
  margin-top: 4.25rem;
}

h2 {
  margin-bottom: -0.5rem;
}
@media screen and (max-width: 767px) {
  h2 {
    margin-top: 3rem;
    margin-bottom: -0.375rem;
  }
}
h2 + div {
  margin-top: 1.875rem;
}
@media screen and (min-width: 768px) {
  h2:first-child {
    margin-top: -1rem;
  }
  h2:last-child {
    margin-bottom: -1rem;
  }
}

h2 + p.typography-heavy,
h3 + p.typography-heavy,
.typography-heading-container + p.typography-heavy {
  padding-right: 13%;
}
@media screen and (max-width: 767px) {
  h2 + p.typography-heavy,
  h3 + p.typography-heavy,
  .typography-heading-container + p.typography-heavy {
    padding-right: 0;
  }
}

address {
  text-transform: uppercase;
}

blockquote:not(figure blockquote) {
  margin-left: 0;
  color: #000000;
  position: relative;
}
blockquote:not(figure blockquote)::before {
  display: block;
  width: 5px;
  height: 100%;
  position: absolute;
  background: #2ca8b3;
  content: "";
}
blockquote:not(figure blockquote) p {
  font-family: trajan-pro-3, serif;
  font-size: 1.5rem;
  padding: 1rem 2rem;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
#guide {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
}
#guide > div {
  gap: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  margin: auto;
  width: 100%;
  height: 100%;
  padding: 0 calc(6.3vw + 1rem);
  box-sizing: border-box;
  max-width: 1920px;
  outline: 3px solid red;
}
#guide > div > div {
  height: 100%;
  background: rgba(0, 50, 255, 0.3);
  outline: 1px solid rgba(0, 0, 255, 0.3);
  outline-offset: -1px;
}

@media screen and (min-width: 1920px) {
  #guide > div {
    max-width: 1920px;
    padding: 0 8.5625rem;
  }
}
[data-aos=fade-in-btm] {
  opacity: 0;
  transform: translateY(10vh);
  transition: opacity 0.7s ease 0.14s, transform 0.595s cubic-bezier(0, 1.1, 0.985, 0.985);
}

.js-intersection-observed[data-aos=fade-in-btm] {
  opacity: 1;
  transform: translateY(0);
}

[data-aos=fade-in] {
  opacity: 0;
  transition: opacity 0.7s ease 0.14s;
}

.js-intersection-observed[data-aos=fade-in] {
  opacity: 1;
}

[data-aos=fade-in-collage] img {
  transition: opacity 0.7s ease 0.14s;
  opacity: 0;
}
[data-aos=fade-in-collage] img:nth-child(1) {
  transition-delay: 0.1s;
}
[data-aos=fade-in-collage] img:nth-child(2) {
  transition-delay: 0s;
}
[data-aos=fade-in-collage] img:nth-child(3) {
  transition-delay: 0.3s;
}
[data-aos=fade-in-collage] img:nth-child(4) {
  transition-delay: 0.6s;
}
[data-aos=fade-in-collage] img:nth-child(5) {
  transition-delay: 0.4s;
}
[data-aos=fade-in-collage] img:nth-child(6) {
  transition-delay: 0.2s;
}
[data-aos=fade-in-collage] img:nth-child(7) {
  transition-delay: 0.5s;
}

.js-intersection-observed[data-aos=fade-in-collage] img {
  opacity: 1;
}

[data-aos-delay="200"] {
  transition-delay: 0s;
}

.js-intersection-observed[data-aos-delay="200"] {
  transition-delay: 200ms;
}

[data-aos-delay="400"] {
  transition-delay: 0s;
}

.js-intersection-observed[data-aos-delay="400"] {
  transition-delay: 400ms;
}

[data-aos-delay="600"] {
  transition-delay: 0s;
}

.js-intersection-observed[data-aos-delay="600"] {
  transition-delay: 600ms;
}

[data-aos-delay="800"] {
  transition-delay: 0s;
}

.js-intersection-observed[data-aos-delay="800"] {
  transition-delay: 800ms;
}

[data-aos-delay="1000"] {
  transition-delay: 0s;
}

.js-intersection-observed[data-aos-delay="1000"] {
  transition-delay: 1000ms;
}

[data-aos-delay="1200"] {
  transition-delay: 0s;
}

.js-intersection-observed[data-aos-delay="1200"] {
  transition-delay: 1200ms;
}

[data-aos-delay="1400"] {
  transition-delay: 0s;
}

.js-intersection-observed[data-aos-delay="1400"] {
  transition-delay: 1400ms;
}

[data-aos-delay="1600"] {
  transition-delay: 0s;
}

.js-intersection-observed[data-aos-delay="1600"] {
  transition-delay: 1600ms;
}

[data-aos-delay="1800"] {
  transition-delay: 0s;
}

.js-intersection-observed[data-aos-delay="1800"] {
  transition-delay: 1800ms;
}

[data-aos-delay="2000"] {
  transition-delay: 0s;
}

.js-intersection-observed[data-aos-delay="2000"] {
  transition-delay: 2000ms;
}

[data-aos-delay="2200"] {
  transition-delay: 0s;
}

.js-intersection-observed[data-aos-delay="2200"] {
  transition-delay: 2200ms;
}

[data-aos-delay="2400"] {
  transition-delay: 0s;
}

.js-intersection-observed[data-aos-delay="2400"] {
  transition-delay: 2400ms;
}

[data-aos-delay="2600"] {
  transition-delay: 0s;
}

.js-intersection-observed[data-aos-delay="2600"] {
  transition-delay: 2600ms;
}

[data-aos-delay="2800"] {
  transition-delay: 0s;
}

.js-intersection-observed[data-aos-delay="2800"] {
  transition-delay: 2800ms;
}

[data-aos-delay="3000"] {
  transition-delay: 0s;
}

.js-intersection-observed[data-aos-delay="3000"] {
  transition-delay: 3000ms;
}

/* banner module: theme */
.bg-cover-photo {
  background-color: #ffffff;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.bg-tinted:after,
.bg-tinted-less:after,
.bg-tinted-more:after,
.bg-tinted-color:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 4;
}

.bg-tinted-less:after {
  background: rgba(0, 0, 0, 0.3);
}

.bg-tinted-more:after {
  background: rgba(0, 0, 0, 0.6);
}

.bg-tinted-color:after {
  background: rgba(0, 86, 131, 0.6);
}

blockquote.quote:before {
  background: none;
}
blockquote.quote {
  font-family: trajan-pro-3, serif;
  color: #ffffff;
  font-weight: 500;
  font-size: 2.5rem;
  line-height: 3rem;
  margin-bottom: 2rem;
}
@media screen and (max-width: 767px) {
  blockquote.quote {
    font-size: 1.5rem;
    line-height: 1.75rem;
    margin: 0 0 1rem 0;
  }
}

.blockquote.title {
  font-size: 2rem;
  font-weight: 400;
}
@media screen and (max-width: 767px) {
  .blockquote.title {
    font-size: 1.25rem;
    font-weight: 400;
  }
}

/* bg module: theme */
.relative {
  position: relative;
}

.bg-gray {
  background-color: #EAF0F4;
}

.bg-white {
  background-color: #ffffff;
}

.box-align-vertically {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.box-align-height {
  height: auto;
}
.box-bg {
  background-color: #E3FEFF;
}
.box-quote {
  width: 120px;
  height: 120px;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .box-quote {
    width: 80px;
    height: 80px;
    top: -3rem;
  }
  .box-quote svg {
    width: 2.5rem;
  }
}

.border-tl {
  box-shadow: -30px -30px 0 0 #E3FEFF;
}
.border-tr {
  box-shadow: 30px -30px 0 0 #E3FEFF;
}
.border-tr-extra {
  box-shadow: 8rem -30px 0 0 #E3FEFF;
}
@media screen and (min-width: 1025px) {
  .border-tr-extra {
    box-shadow: 16rem -30px 0 0 #E3FEFF;
  }
}
.border-bl {
  box-shadow: -30px 30px 0 0 #E3FEFF;
}
.border-br {
  box-shadow: 30px 30px 0 0 #E3FEFF;
}

.bar {
  background-color: #000000;
  width: 40%;
  height: 2px;
}
.bar.inverted {
  background-color: #ffffff;
}
.bar-center {
  margin: 1rem auto 0;
}
.bar-left {
  margin: 1rem auto 0 0;
}
.bar-right {
  margin: 1rem 0 0 auto;
}

/* bio module: theme */ /* button module: core */
.btn,
.button-icon {
  background-color: #E3FEFF;
  width: max-content;
  display: flex;
  text-transform: capitalize;
  position: relative;
  overflow: hidden;
  padding: 1rem 2.25rem;
  min-height: 2.25rem;
  border-radius: 3px;
  align-items: center;
  justify-content: center;
  z-index: 0;
  font-family: franklin-gothic-urw, sans-serif;
  color: #2ca8b3;
  font-weight: 700;
  transition: all 0.3s ease-in;
  border-bottom: none;
}
@media screen and (max-width: 767px) {
  .btn,
  .button-icon {
    margin-top: 1rem !important;
    width: auto;
  }
}
.btn:hover,
.button-icon:hover {
  background-color: #2ca8b3;
  color: #E3FEFF;
  text-decoration: none;
  border-bottom: none;
}

@media screen and (max-width: 767px) {
  .padding-section-content .button:last-child {
    margin-bottom: 24px;
  }
}

.button-icon svg {
  display: block;
  width: 1rem;
  height: 1rem;
  margin-right: 0.625rem;
}

.inverted a.btn,
.inverted a.button-icon,
a.btn.inverted,
a.button-icon.inverted {
  background-color: #2ca8b3;
  color: #E3FEFF;
  white-space: nowrap;
}
@media screen and (max-width: 767px) {
  .inverted a.btn,
  .inverted a.button-icon,
  a.btn.inverted,
  a.button-icon.inverted {
    white-space: unset;
  }
}
.inverted a.btn:hover,
.inverted a.button-icon:hover,
a.btn.inverted:hover,
a.button-icon.inverted:hover {
  background-color: #E3FEFF;
  color: #2ca8b3;
}

a.svg, .svg {
  position: relative;
}
a.svg.inverted svg, .svg.inverted svg {
  stroke: #ffffff;
}
a.svg svg, .svg svg {
  position: absolute;
  top: -1.5rem;
  left: 50%;
  right: 0;
  bottom: 0;
  pointer-events: none;
  fill: none;
  stroke: #848484;
  stroke-width: 0.5px;
  margin: 0 auto;
  transform: translateX(-50%);
}
a.svg svg path, .svg svg path {
  stroke-dasharray: 1;
  stroke-dashoffset: 1;
  transition: stroke-dashoffset 0.4s cubic-bezier(0.7, 0, 0.3, 1);
}
a.svg:hover svg path, .svg:hover svg path {
  stroke-dashoffset: 0;
  transition-timing-function: cubic-bezier(0.8, 1, 0.7, 1);
  transition-duration: 0.4s;
}

.wide {
  width: 320px;
  box-sizing: border-box;
}
@media screen and (max-width: 767px) {
  .wide {
    width: unset;
  }
}

.column-2 {
  column-count: 2;
  column-width: max-content;
}
@media screen and (max-width: 767px) {
  .column-2 {
    column-count: 1;
    column-width: auto;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (min-width: 768px) {
  .container-offset-left {
    padding-right: calc(3.75vw + 1rem);
    margin-right: calc(-3.75vw + -1rem);
    position: relative;
    z-index: 2;
  }
}

@media screen and (min-width: 768px) {
  .container-offset-right {
    padding-left: calc(3.75vw + 1rem) !important;
    margin-left: calc(-3.75vw + -1rem) !important;
    margin-top: 3.125vw;
    margin-bottom: 3.125vw;
  }
}
@media screen and (min-width: 1921px) {
  .container-offset-right {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem;
  }
}
@media screen and (max-width: 767px) {
  .container-offset-right.padding-section-content {
    padding-bottom: 0 !important;
  }
}

.padding-default .container-offset-left,
.padding-default .container-offset-right {
  padding-left: 0;
  padding-right: 0;
}

.container-before-after-sample {
  position: relative;
  box-sizing: border-box;
}
.container-before-after-sample h2 {
  font-size: 2.25rem;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 1.6666666667;
  font-family: trajan-pro-3, serif;
  color: #2ca8b3;
  text-transform: capitalize;
  font-size: 4.375rem;
  margin-top: 1.875rem;
}
@media screen and (max-width: 767px) {
  .container-before-after-sample h2 {
    font-size: 2rem;
  }
}
@media screen and (max-width: 1024px) {
  .container-before-after-sample h2 {
    line-height: 1;
  }
}
.container-before-after-sample h2 span {
  margin: 0 0 -2.125rem 0.375rem;
}
@media screen and (max-width: 767px) {
  .container-before-after-sample h2 {
    font-size: 3.375rem;
  }
  .container-before-after-sample h2 span {
    margin-bottom: -0.625rem;
  }
}
.container-before-after-sample .hr {
  max-width: 16.25rem;
  margin: 1.875rem auto 2.5rem auto;
}
@media screen and (max-width: 767px) {
  .container-before-after-sample .hr {
    margin: 0.625rem auto 1.25rem auto;
  }
}
.container-before-after-sample h2,
.container-before-after-sample .hr,
.container-before-after-sample figcaption {
  position: relative;
  z-index: 3;
}
.container-before-after-sample figure {
  display: block;
  width: 100%;
}
.container-before-after-sample figure img {
  position: absolute;
  width: 50%;
  height: 100%;
  top: 0;
}
.container-before-after-sample figure img:first-child {
  left: 0;
}
.container-before-after-sample figure img + img {
  right: 0;
}
.container-before-after-sample figure figcaption {
  font-size: 1.9375rem;
  font-weight: 400;
  letter-spacing: 0.08em;
  line-height: 1.1612903226;
  font-family: trajan-pro-3, serif;
  letter-spacing: 0;
  text-transform: capitalize;
}
@media screen and (max-width: 767px) {
  .container-before-after-sample figure figcaption {
    line-height: 1.25;
  }
}
.container-before-after-sample .read-more {
  right: -6.25rem;
  bottom: -6.25rem;
}
@media screen and (max-width: 1440px) {
  .container-before-after-sample .read-more {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
    display: block;
    text-transform: uppercase;
    transition: background-color 0.3s ease-in, color 0.3s ease-in;
    padding: 1.25rem 2.25rem;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 25rem;
    color: #ffffff;
    background: #000000;
    width: auto;
    height: auto;
    margin-top: 1.25rem;
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
    display: block;
    text-transform: uppercase;
    transition: background-color 0.3s ease-in, color 0.3s ease-in;
    padding: 1.25rem 2.25rem;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 25rem;
    color: #000000;
    background: #2ca8b3;
    position: static;
    right: auto;
    bottom: auto;
    margin: 1.875rem auto;
  }
  .container-before-after-sample .read-more:hover {
    background: #2ca8b3;
    color: #000000;
  }
  .container-before-after-sample .read-more svg,
  .container-before-after-sample .read-more .read-more__circle,
  .container-before-after-sample .read-more .read-more__text,
  .container-before-after-sample .read-more .read-more__arrow {
    display: none !important;
  }
  .container-before-after-sample .read-more:hover {
    background: #000000;
    color: #ffffff;
  }
}
@media screen and (max-width: 767px) {
  .container-before-after-sample .read-more {
    margin-bottom: 0.625rem;
  }
}

.container-wellness {
  display: grid;
  grid-template-columns: 55fr 41fr;
  position: relative;
  z-index: 5;
  padding: 5.2083333333vw 0 3.9583333333vw 0;
}
@media screen and (max-width: 767px) {
  .container-wellness {
    display: block;
  }
}
.container-wellness .bg-gray-2 {
  position: relative;
  bottom: -7.9166666667vw;
}
.container-wellness .nav ul {
  gap: 2.7083333333vw;
}
@media screen and (min-width: 1921px) {
  .container-wellness {
    padding: 6.25rem 0 4.75rem 0;
  }
  .container-wellness .bg-gray-2 {
    bottom: -9.5rem;
  }
  .container-wellness .nav ul {
    gap: 3.25rem;
  }
}
@media screen and (max-width: 1600px) {
  .container-wellness {
    grid-template-columns: 60fr 40fr;
    gap: 0;
  }
  .container-wellness .bg-gray-2 {
    padding: 3.9583333333vw;
  }
  .container-wellness .nav ul {
    gap: 1.25rem;
  }
}

@media screen and (min-width: 768px) {
  .container-gallery-promo .container {
    padding: 8.9583333333vw 4.0625vw;
  }
}
@media screen and (min-width: 1921px) {
  .container-gallery-promo .container {
    padding: 10.75rem 4.875rem;
  }
}
.container-gallery-promo .bg-cover-photo {
  background-image: url(/wp-content/themes/georgiahairinstitute_com/images/bg-gallery-promo.jpg);
}
@media screen and (min-width: 768px) {
  .container-gallery-promo .bg-cover-photo {
    position: absolute;
    width: 50%;
    height: 100%;
    top: 0;
    right: 0;
  }
}
@media screen and (max-width: 767px) {
  .container-gallery-promo .bg-cover-photo {
    width: 100%;
    height: 60vw;
  }
}
.container-gallery-promo h2 {
  font-size: 2.25rem;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 1.6666666667;
  font-family: trajan-pro-3, serif;
  color: #2ca8b3;
  text-transform: capitalize;
  font-size: 4.375rem;
  position: relative;
  width: max-content;
  margin: auto;
}
@media screen and (max-width: 767px) {
  .container-gallery-promo h2 {
    font-size: 2rem;
  }
}
@media screen and (max-width: 1024px) {
  .container-gallery-promo h2 {
    line-height: 1;
  }
}
@media screen and (min-width: 768px) {
  .container-gallery-promo h2 .typography-script {
    display: block;
    position: absolute;
    margin: 2rem 0 0 -4.25rem;
  }
}
@media screen and (max-width: 767px) {
  .container-gallery-promo h2 {
    padding: 0 24px;
    margin-top: 30px;
  }
  .container-gallery-promo h2 .typography-script {
    display: inline !important;
  }
}
@media screen and (max-width: 767px) {
  .container-gallery-promo .nav {
    margin: 24px;
  }
}
.container-gallery-promo .nav .hr {
  margin-top: 1.09375vw;
  margin-bottom: 0.6770833333vw;
}
@media screen and (min-width: 1921px) {
  .container-gallery-promo .nav .hr {
    margin-top: 1.3125rem;
    margin-bottom: 0.8125rem;
  }
}
.container-gallery-promo .nav ul {
  gap: 2.0833333333vw;
}
@media screen and (min-width: 1921px) {
  .container-gallery-promo .nav ul {
    gap: 2.5rem;
  }
}

.container-column-menu {
  margin-top: 1rem;
}
.container-column-menu a {
  display: block;
}
.container-column-menu h2 {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
@media screen and (min-width: 768px) {
  .container-column-menu h2 {
    min-height: 8rem;
  }
}
@media screen and (max-width: 1200px) {
  .container-column-menu h2 {
    font-size: 2.25rem;
    line-height: 1.25;
  }
}
@media screen and (max-width: 767px) {
  .container-column-menu > div:not(:first-child) > h2 {
    margin-top: 24px;
  }
}
.container-column-menu img {
  margin: 2.25rem auto 1.875rem auto;
}

.container-tiled-corner-box {
  margin: 3.125rem 3.125rem 3.125rem 1.875rem;
}
@media screen and (max-width: 767px) {
  .container-tiled-corner-box {
    margin: 30px;
  }
  .container-tiled-corner-box .padding-xl2 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
.container-tiled-corner-box .container-tiled-corner-box__tiles {
  position: relative;
}
.container-tiled-corner-box .container-tiled-corner-box__tiles > * {
  position: relative;
  z-index: 1;
}
.container-tiled-corner-box .container-tiled-corner-box__tiles::before, .container-tiled-corner-box .container-tiled-corner-box__tiles::after {
  content: "";
  display: block;
  position: absolute;
  width: 80%;
  height: 78%;
  z-index: 0;
  background: #C4C4C4 url(/wp-content/themes/georgiahairinstitute_com/images/bg-tile-light-sm.jpg) center center/cover no-repeat;
  opacity: 0.4;
}
.container-tiled-corner-box .container-tiled-corner-box__tiles::before {
  top: -3.125rem;
  right: -3.125rem;
}
.container-tiled-corner-box .container-tiled-corner-box__tiles::after {
  bottom: -3.125rem;
  left: -3.125rem;
}
@media screen and (max-width: 767px) {
  .container-tiled-corner-box .container-tiled-corner-box__tiles::before {
    top: -1.875rem;
    right: -1.875rem;
  }
  .container-tiled-corner-box .container-tiled-corner-box__tiles::after {
    bottom: -1.875rem;
    left: -1.875rem;
  }
}

.container-border-left,
.container-border-right {
  position: relative;
}
.container-border-left::before,
.container-border-right::before {
  content: "";
  display: block;
  width: 0.05rem;
  height: 100%;
  position: absolute;
  background: #2ca8b3;
  top: 0;
}
@media screen and (max-width: 767px) {
  .container-border-left::before,
  .container-border-right::before {
    display: none;
  }
  .container-border-left.padding-section-content,
  .container-border-right.padding-section-content {
    padding: 0 24px;
  }
  .container-border-left:first-child,
  .container-border-right:first-child {
    margin-bottom: 24px;
  }
  .container-border-left:not(:first-child),
  .container-border-right:not(:first-child) {
    margin-top: 24px;
  }
}

.container-border-left::before {
  left: 0;
}
@media screen and (max-width: 767px) {
  .container-border-left {
    border-left: 0.05rem solid #2ca8b3;
  }
}

.container-border-right::before {
  right: 0;
}
@media screen and (max-width: 767px) {
  .container-border-right {
    border-right: 0.05rem solid #2ca8b3;
  }
}

.container-box-sizing {
  box-sizing: border-box;
}
@media screen and (min-width: 768px) {
  .container-box-sizing {
    padding-bottom: 5.625rem;
  }
}
.container-box-sizing svg {
  display: block;
  margin: 0 auto 3.125rem auto;
  width: 100%;
  height: 2.25rem;
  max-width: 17rem;
}

.container-review-tiles {
  list-style: none;
  padding: 0.875rem 0 5.2083333333vw 0;
}
@media screen and (min-width: 1921px) {
  .container-review-tiles {
    padding-bottom: 6.25rem;
  }
}
.container-review-tiles a {
  display: block;
  text-align: center;
  background: #131c1d;
  padding: 3.375rem 2rem 2.875rem 2rem;
  overflow: hidden;
  position: relative;
}
@media screen and (max-width: 767px) {
  .container-review-tiles a {
    padding: 32px 24px;
  }
}
.container-review-tiles a svg {
  display: block;
  width: 4.0625rem;
  height: 2.5rem;
  margin: 0 auto 0.375rem auto;
  fill: #2ca8b3;
  transition: fill 0.3s ease-in;
}
.container-review-tiles a span:not(.sr-only) {
  display: block;
  font-size: 1.625rem;
  font-weight: 500;
  letter-spacing: 0.5rem;
  line-height: 1.875rem;
  text-transform: uppercase;
  color: #ffffff;
  transition: color 0.3s ease-in;
}
@media screen and (min-width: 768px) {
  .container-review-tiles a span:not(.sr-only) {
    font-size: 1.875rem;
    margin: 2.5rem auto 0.75rem auto;
  }
}
.container-review-tiles a::after {
  display: block;
  content: "";
  position: absolute;
  background-color: #2ca8b3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  transition: transform 0.3s ease-in;
  transform: translateX(-100%);
}
.container-review-tiles a:hover span {
  color: #000000;
}
.container-review-tiles a:hover svg {
  fill: #000000;
}
.container-review-tiles a:hover::after {
  transform: translateX(0);
}

.section-blue-bar {
  background-image: url("/wp-content/themes/georgiahairinstitute_com/images/blue-bar.svg");
  background-repeat: repeat-y;
  background-color: #fff;
  position: relative;
  max-width: 865px;
  padding: 6rem 6rem 6rem 10.5rem;
  background-position-x: -1.15rem;
  box-shadow: -15px 15px 20px 0px rgba(66, 66, 66, 0.1882352941);
}

.section-background-img-left {
  position: absolute;
  left: 0;
  top: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  animation: zoomOut 5s ease-out 1;
  transform: scale(1);
  max-width: 50%;
}

.diagram {
  position: relative;
}
.diagram .btn {
  position: absolute;
  bottom: 40px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
@media screen and (min-width: 1024px) and (max-width: 1440px) {
  .diagram .btn {
    bottom: 100px;
  }
}
@media screen and (max-width: 767px) {
  .diagram .btn {
    left: 15px;
    right: 15px;
  }
}

/* footer module: theme */ /* header module: theme */
.header {
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: auto;
  position: absolute;
  width: 100%;
  z-index: 99;
}
.header .header__content {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  padding: 2rem 2rem;
  transition: all 0.3s ease-in;
}
@media screen and (max-width: 767px) {
  .header .header__content {
    padding: 0;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .header .header__content {
    padding: 1.4rem 2rem;
  }
}
@media screen and (min-width: 768px) {
  .header .header__content--logo {
    display: none;
  }
}
.header .header__content--logo a {
  display: flex;
  padding: 0.625rem 1rem !important;
  max-height: 60px;
  width: auto;
  max-width: 190px;
}
@media screen and (max-width: 767px) {
  .header .header__content--logo a {
    padding: 1.1rem 1rem !important;
    height: 2.5rem;
  }
}
.header .header__content--column div .logo {
  max-width: 320px;
  padding: 0;
  transition: all 0.3s ease-in;
}
.header .header__content--column div:nth-child(1) {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 2rem;
}
.header .header__content--column div:nth-child(2) {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 5rem;
  color: #ffffff;
}
@media screen and (min-width: 1921px) {
  .header .header__content--column div:nth-child(2) {
    margin-right: 6.5rem;
  }
}
.header .header__content--column div:nth-child(2) a {
  transition: all 0.3s ease-in;
}
.header .header__content--column div:nth-child(2) span {
  padding: 0.625rem;
}
@media screen and (max-width: 767px) {
  .header .header__content--column {
    display: none;
  }
}
.header a:not(.header__skip) {
  color: #ffffff;
  text-transform: uppercase;
  padding: 0.625rem;
}
.header a:not(.header__skip):hover {
  color: #2ca8b3;
}
.header nav ul {
  display: flex;
  gap: 5rem;
  gap: 4.1vw;
}
@media screen and (max-width: 1200px) {
  .header nav {
    display: none;
  }
}
.header .header__photo-gallery-link {
  position: absolute;
  text-align: center;
  bottom: 0;
}
.header .header__photo-gallery-link,
.header .header__photo-gallery-link a,
.header .header__photo-gallery-link svg {
  display: block;
  width: 6.125rem;
  height: 5.625rem;
}
.header .header__photo-gallery-link svg {
  fill: #ffffff;
}

.header__skip {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  padding: 0.375rem 1.25rem;
  position: fixed;
  top: 1.625rem;
  left: 3rem;
  transform: translateX(calc(-100% - 48px));
  height: 3rem;
  transition: transform 0.3s ease-in, color 0.3s ease-in;
  z-index: 7;
}
.header__skip:focus {
  transform: translateX(0);
  opacity: 1;
}
.header__skip svg {
  margin-left: 1rem;
  width: 2rem;
  height: 1.375rem;
  transition: fill 0.3s ease-in;
}
.header__skip:hover svg {
  fill: #2ca8b3;
}

@media screen and (max-width: 767px) {
  .header {
    top: 0;
    height: 60px;
    z-index: 99;
  }
  .header .header__content {
    min-height: 60px;
  }
  .header .header__left {
    left: 0;
  }
  .header .header__left .logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: auto;
    padding: 0 16px;
  }
  .header .header__left .logo svg {
    width: 190px;
    height: 40px;
  }
  .header .header__left .logo svg .logo__mobile {
    display: block;
  }
  .header .header__left .logo svg .logo__desktop {
    display: none;
  }
  .header .header__left a:not(.logo) {
    display: none;
  }
}
hr,
.hr,
.hr-short {
  position: relative;
  border: none;
  overflow: visible;
  height: 1.4375rem;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1 30" style="transform:rotate(45deg);fill:black;"><rect width="1" height="30" /></svg>') 5.75rem center/1.875rem 1.875rem no-repeat;
  opacity: 0.33;
  margin-top: 2.25rem;
  margin-bottom: 2.25rem;
}
hr:before, hr:after,
.hr:before,
.hr:after,
.hr-short:before,
.hr-short:after {
  display: block;
  position: absolute;
  background: #000000;
  height: 0.0625rem;
  content: "";
  top: 0.6875rem;
}
hr:before,
.hr:before,
.hr-short:before {
  width: 6.25rem;
  left: 0;
}
hr:after,
.hr:after,
.hr-short:after {
  width: calc(100% - 7.0625rem);
  right: 0;
}

nav hr,
nav .hr,
.banner hr,
.banner .hr,
.quote hr,
.quote .hr,
.hr--short {
  max-width: 38.125rem;
  background-position: 1.625rem center;
}
nav hr:before,
nav .hr:before,
.banner hr:before,
.banner .hr:before,
.quote hr:before,
.quote .hr:before,
.hr--short:before {
  width: 2.125rem;
}
nav hr:after,
nav .hr:after,
.banner hr:after,
.banner .hr:after,
.quote hr:after,
.quote .hr:after,
.hr--short:after {
  width: calc(100% - 2.9375rem);
}

.hr-initials {
  position: relative;
  width: 100%;
  height: 3.25rem;
}
.hr-initials::before, .hr-initials::after {
  content: "";
  display: block;
  position: absolute;
  height: 0.0625rem;
  width: calc(50% - 4.75rem);
  background: #000000;
  top: 50%;
}
.hr-initials::before {
  left: 0;
}
.hr-initials::after {
  right: 0;
}
.hr-initials svg {
  width: 3.625rem;
  height: 3.25rem;
  position: absolute;
  display: block;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
}
.hr-initials + h2 {
  margin-top: 1.875rem;
}

.inverted .hr-initials::before, .inverted .hr-initials::after,
.hr-initials.inverted::before,
.hr-initials.inverted::after {
  background: rgba(255, 255, 255, 0.3);
}
.inverted .hr-initials svg,
.hr-initials.inverted svg {
  fill: #ffffff;
}

nav hr:last-of-type:not(:first-child),
nav .hr:last-of-type:not(:first-child) {
  transform: rotate(180deg);
}

nav hr:last-of-type:not(:first-child),
nav .hr:last-child,
hr.hr--flipped,
.hr.hr--flipped {
  transform: rotate(180deg);
}

hr.inverted,
.hr.inverted,
.inverted hr,
.inverted .hr,
.banner hr,
.banner .hr {
  opacity: 1;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1 30" style="transform:rotate(45deg);fill:white;"><rect width="1" height="30" /></svg>');
}
hr.inverted:before, hr.inverted:after,
.hr.inverted:before,
.hr.inverted:after,
.inverted hr:before,
.inverted hr:after,
.inverted .hr:before,
.inverted .hr:after,
.banner hr:before,
.banner hr:after,
.banner .hr:before,
.banner .hr:after {
  background: #ffffff;
}

.inverted .hr--faded {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1 30" style="transform:rotate(45deg);fill:rgba(255,255,255,0.3);"><rect width="1" height="30" /></svg>');
}
.inverted .hr--faded:before, .inverted .hr--faded:after {
  background: rgba(255, 255, 255, 0.3);
}

@media screen and (min-width: 768px) {
  .img-full-height {
    height: 100%;
    object-fit: cover;
  }
}
.img-gutter-pull-right {
  width: calc(100% + 1rem);
  margin-right: -1rem;
}

/* logo module: theme */
.logo,
.logo img,
.logo svg {
  display: block;
}
.logo svg {
  transform: scale(0.999);
}

.margin-top-16 {
  margin-top: 1rem !important;
}

.margin-bottom-16 {
  margin-bottom: 1rem !important;
}

.margin-top-25 {
  margin-top: 1.5625rem !important;
}

.margin-bottom-25 {
  margin-bottom: 1.5625rem !important;
}

.margin-top-45 {
  margin-top: 2.34375vw;
}
@media screen and (min-width: 1921px) {
  .margin-top-45 {
    margin-top: 2.8125rem;
  }
}

.margin-bottom-45 {
  margin-bottom: 2.34375vw;
}
@media screen and (min-width: 1921px) {
  .margin-bottom-45 {
    margin-bottom: 2.8125rem;
  }
}

.margin-bottom-66 {
  margin-bottom: 3.4375vw;
}
@media screen and (min-width: 1921px) {
  .margin-bottom-66 {
    margin-bottom: 4.125rem;
  }
}

.margin-top-70 {
  margin-top: 3.6458333333vw;
}
@media screen and (min-width: 1921px) {
  .margin-top-70 {
    margin-top: 4.375rem;
  }
}

.margin-default {
  margin: 3.9583333333vw auto;
}
@media screen and (min-width: 1921px) {
  .margin-default {
    margin: 4.75rem auto;
  }
}
@media screen and (max-width: 767px) {
  .margin-default {
    margin: 24px auto;
  }
}

.margin-top-default {
  margin-top: 3.9583333333vw;
}
@media screen and (min-width: 1921px) {
  .margin-top-default {
    margin-top: 4.75rem;
  }
}

.margin-top-100 {
  margin-top: 5.2083333333vw;
}
@media screen and (min-width: 1921px) {
  .margin-top-100 {
    margin-top: 6.25rem;
  }
}

.margin-top-125 {
  margin-top: 6.5104166667vw;
}
@media screen and (min-width: 1921px) {
  .margin-top-125 {
    margin-top: 7.8125rem;
  }
}

@media screen and (max-width: 767px) {
  .banner + .margin-top-125 {
    margin-top: 0;
  }
}

.margin-bottom-125 {
  margin-bottom: 6.5104166667vw;
}
@media screen and (min-width: 1921px) {
  .margin-bottom-125 {
    margin-bottom: 7.8125rem;
  }
}

.margin-top-180 {
  margin-top: 9.375vw;
}
@media screen and (min-width: 1921px) {
  .margin-top-180 {
    margin-top: 11.25rem;
  }
}

.margin-top-200 {
  margin-top: 10.4166666667vw;
}
@media screen and (min-width: 1921px) {
  .margin-top-200 {
    margin-top: 12.5rem;
  }
}

.margin-bottom-200 {
  margin-bottom: 10.4166666667vw;
}
@media screen and (min-width: 1921px) {
  .margin-bottom-200 {
    margin-bottom: 12.5rem;
  }
}

.margin-top-250 {
  margin-top: 13.0208333333vw;
}
@media screen and (min-width: 1921px) {
  .margin-top-250 {
    margin-top: 15.625rem;
  }
}

.margin-top-400 {
  margin-top: 26.0416666667vw;
}
@media screen and (min-width: 1921px) {
  .margin-top-400 {
    margin-top: 31.25rem;
  }
}

.margin-bottom-300 {
  margin-bottom: 15.625vw;
}
@media screen and (min-width: 1921px) {
  .margin-bottom-300 {
    margin-bottom: 18.75rem;
  }
}

/* menu module: theme */
#menu {
  position: fixed;
  display: grid;
  grid-template-columns: 7.5fr 4.5fr;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateX(100%);
  transition: transform 0.595s cubic-bezier(0, 1.1, 0.985, 0.985);
  overflow: auto;
}
@media screen and (max-width: 1920px) {
  #menu {
    grid-template-columns: 8fr 4fr;
  }
}
@media screen and (max-width: 1200px) {
  #menu {
    grid-template-columns: 1fr;
  }
  #menu > nav:last-child {
    padding-bottom: 12.5rem;
  }
}
#menu > nav:first-child.menu__main {
  background: #131c1d;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  max-height: 100%;
  opacity: 0;
  transition: opacity 0.2s ease;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
@media screen and (max-width: 1024px) {
  #menu > nav:first-child.menu__main {
    justify-content: flex-start;
    padding-bottom: 20px !important;
  }
}
@media screen and (max-width: 1200px) {
  #menu > nav:first-child.menu__main {
    padding-bottom: 50px;
  }
}
#menu > nav:first-child.menu__main--align {
  z-index: 10;
}
#menu > nav:first-child.menu__main .menu__main--logo {
  position: absolute;
  top: 4%;
  left: 8%;
}
@media screen and (max-width: 767px) {
  #menu > nav:first-child.menu__main .menu__main--logo {
    position: relative;
    top: 0% !important;
    left: 3% !important;
  }
}
@media screen and (max-width: 1200px) {
  #menu > nav:first-child.menu__main .menu__main--logo {
    position: relative;
    top: 0%;
    left: 4%;
  }
}
#menu > nav:first-child.menu__main .menu__main--logo svg {
  width: 90px;
  height: 70px;
}
#menu > nav:first-child a {
  font-size: 2.25rem;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 1.6666666667;
  font-family: trajan-pro-3, serif;
  color: #2ca8b3;
  text-transform: capitalize;
  display: block;
  color: #ffffff;
  padding: 0.625rem;
}
@media screen and (max-width: 767px) {
  #menu > nav:first-child a {
    font-size: 2rem;
  }
}
@media screen and (max-width: 1024px) {
  #menu > nav:first-child a {
    line-height: 1;
  }
}
@media screen and (max-width: 1920px) {
  #menu > nav:first-child a {
    font-size: 2rem;
    line-height: 1.25;
  }
}
@media screen and (max-width: 1024px) {
  #menu > nav:first-child a {
    font-size: 1.5rem !important;
  }
}
#menu > nav:first-child a:focus, #menu > nav:first-child a:hover {
  color: #2ca8b3;
}
#menu > nav:first-child a:focus + ul {
  opacity: 1;
  z-index: 9;
}
@media screen and (min-width: 1025px) {
  #menu > nav:first-child > ul {
    margin: 3rem 27vw 3rem 5vw;
    max-width: max-content;
    position: relative;
    z-index: 10;
  }
  #menu > nav:first-child > ul button {
    display: none;
  }
  #menu > nav:first-child > ul ul {
    position: absolute;
    left: calc(100% - 0.875rem);
    margin-top: -5.25rem;
    width: 30vw;
    max-width: 37.5rem;
    box-sizing: border-box;
    padding-left: 5vw;
    padding-top: 1.5625rem;
    opacity: 0;
    z-index: -1;
    transition: opacity 0.3s ease-in;
  }
  #menu > nav:first-child > ul ul:focus-within {
    opacity: 1;
    z-index: 9;
  }
  #menu > nav:first-child > ul ul a {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1.125rem;
    font-weight: 400;
    letter-spacing: 0.01em;
    line-height: 1.6666666667;
    font-family: franklin-gothic-urw, sans-serif;
    color: #000000;
    padding: 0.6875rem 1rem;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #ffffff;
    font-size: 1rem;
  }
}
@media screen and (min-width: 1025px) and (max-width: 767px) {
  #menu > nav:first-child > ul ul a {
    line-height: 1.33;
  }
}
@media screen and (min-width: 1025px) {
  #menu > nav:first-child > ul ul a svg {
    display: block;
    width: 2.625rem;
    height: 1.8125rem;
    fill: #ffffff;
    opacity: 0;
    transform: translateX(0.3125rem);
    transition: transform 0.3s ease-in, opacity 0.3s ease-in;
  }
}
@media screen and (min-width: 1025px) {
  #menu > nav:first-child > ul ul a:hover, #menu > nav:first-child > ul ul a:focus {
    color: #2ca8b3;
  }
  #menu > nav:first-child > ul ul a:hover svg, #menu > nav:first-child > ul ul a:focus svg {
    transform: translateX(1.25rem);
    opacity: 1;
  }
}
@media screen and (min-width: 1025px) {
  #menu > nav:first-child > ul > li {
    padding-right: 1.875rem;
  }
  #menu > nav:first-child > ul > li:hover > a, #menu > nav:first-child > ul > li:focus-within > a {
    color: #2ca8b3;
  }
  #menu > nav:first-child > ul > li:hover::before, #menu > nav:first-child > ul > li:focus-within::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    left: 100%;
  }
  #menu > nav:first-child > ul > li:hover ul, #menu > nav:first-child > ul > li:focus-within ul {
    opacity: 1;
    z-index: 9;
  }
}
@media screen and (max-width: 1920px) {
  #menu > nav:first-child > ul > ul {
    padding-left: 1vw;
  }
  #menu > nav:first-child > ul > ul a {
    line-height: 1.25;
  }
}
@media screen and (max-width: 1024px) {
  #menu > nav:first-child {
    transition: none;
    align-items: flex-start;
    padding-top: 30px;
    padding-bottom: 50px;
    background-size: auto;
    background-position: 0 0;
    background-repeat: repeat-y;
  }
  #menu > nav:first-child a {
    color: #ffffff;
  }
  #menu > nav:first-child > ul {
    width: 100%;
    box-sizing: border-box;
    padding: 24px;
    z-index: 99;
  }
  #menu > nav:first-child > ul > li {
    position: relative;
  }
  #menu > nav:first-child > ul > li a {
    font-size: 1.9375rem;
    font-weight: 400;
    letter-spacing: 0.08em;
    line-height: 1.1612903226;
    font-family: trajan-pro-3, serif;
    letter-spacing: 0;
    text-transform: capitalize;
    width: calc(100% - 50px);
    text-transform: none;
  }
}
@media screen and (max-width: 1024px) and (max-width: 767px) {
  #menu > nav:first-child > ul > li a {
    line-height: 1.25;
  }
}
@media screen and (max-width: 1024px) {
  #menu > nav:first-child > ul > li .menu__sub-menu-wrapper {
    overflow: hidden;
    height: 0;
    transition: height 0.3s ease-in;
  }
}
@media screen and (max-width: 1024px) {
  #menu > nav:first-child > ul > li ul {
    margin: 0;
    padding: 0 0 24px 24px;
    opacity: 0;
    transition: opacity 0.3s ease-in;
  }
  #menu > nav:first-child > ul > li ul a {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1.125rem;
    font-weight: 400;
    letter-spacing: 0.01em;
    line-height: 1.6666666667;
    font-family: franklin-gothic-urw, sans-serif;
    color: #000000;
    color: #ffffff;
    font-size: 1rem !important;
    text-transform: uppercase;
  }
}
@media screen and (max-width: 1024px) and (max-width: 767px) {
  #menu > nav:first-child > ul > li ul a {
    line-height: 1.33;
  }
}
@media screen and (max-width: 1024px) {
  #menu > nav:first-child > ul > li ul a svg {
    display: none;
  }
}
@media screen and (max-width: 1024px) {
  #menu > nav:first-child > ul > li button {
    background: transparent;
    width: 40px;
    height: 40px;
    position: absolute;
    right: -8px;
    top: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #menu > nav:first-child > ul > li button:before, #menu > nav:first-child > ul > li button:after {
    display: block;
    content: "";
    width: 18px;
    height: 2px;
    background: #ffffff;
    position: absolute;
    opacity: 1;
    transition: transform 0.3s ease-in, opacity 0.3s ease-in;
  }
  #menu > nav:first-child > ul > li button:after {
    transform: rotate(90deg);
  }
}
@media screen and (max-width: 1024px) {
  #menu > nav:first-child > ul > li.menu__sub-menu-expanded > a {
    color: #2ca8b3;
  }
  #menu > nav:first-child > ul > li.menu__sub-menu-expanded ul {
    opacity: 1;
  }
  #menu > nav:first-child > ul > li.menu__sub-menu-expanded button:before {
    opacity: 1;
  }
  #menu > nav:first-child > ul > li.menu__sub-menu-expanded button:after {
    transform: rotate(0deg);
    opacity: 0;
  }
}
#menu aside {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.8s ease 0.1s;
  position: relative;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 50px;
}
@media screen and (max-width: 1200px) {
  #menu aside {
    margin-top: 50px;
  }
}
#menu aside .company-info {
  transition: opacity 0.8s ease 0.4s;
  opacity: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
#menu aside .company-info .logo {
  margin: auto;
}
#menu aside .company-info .logo img {
  max-width: 350px;
  margin: 0 auto;
}
#menu aside .company-info .logo .phone {
  margin: 2rem auto;
  text-align: center;
}
#menu aside .company-info .logo .phone a {
  margin: 0 0.5rem;
  text-transform: uppercase;
  transition: all 0.3s ease-in;
}
@media screen and (max-width: 767px) {
  #menu aside .company-info .logo .phone a {
    margin: 0 0 1rem;
  }
}
#menu aside .company-info .logo .phone a:hover {
  color: #2ca8b3;
}
#menu aside .company-info .logo .phone span {
  font-weight: 500;
  padding-left: 6px;
}
#menu aside .company-info a {
  color: #000000;
}
#menu aside .company-info a:hover {
  color: #2ca8b3;
}
#menu aside .company-info a:hover.btn {
  color: #2ca8b3;
}
#menu aside .company-info a.btn {
  color: #E3FEFF;
}
@media screen and (max-width: 1200px) {
  #menu aside .company-info a.btn {
    margin-bottom: 3rem;
  }
}
#menu aside .company-info address {
  margin-top: 10px;
  text-transform: capitalize;
}
#menu aside .company-info address p {
  color: #ffffff;
  border: 0;
}
#menu aside .company-info address a {
  color: #ffffff;
}
#menu aside .company-info address a:hover {
  color: #E3FEFF;
}
#menu aside .social {
  transition: opacity 0.8s ease 0.6s;
  opacity: 0;
  display: flex;
  justify-content: center;
}
#menu aside .social ul a svg {
  fill: #000000;
}
#menu aside .social ul a:hover svg {
  fill: #2ca8b3;
}

#menu-trigger {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.1875rem 0.25rem;
  position: fixed;
  top: 2.9375rem;
  right: 3.1vw;
  background: transparent;
  z-index: 999;
  transition: all 0.3s ease-in;
  outline-color: #ffffff !important;
}
#menu-trigger svg {
  width: 3.75rem;
  height: 2.3125rem;
  transition: fill 0.2s ease;
  fill: #ffffff;
}
#menu-trigger svg rect {
  transition: transform 0.3s ease;
  transform-origin: center;
}
@media screen and (max-width: 767px) {
  #menu-trigger {
    right: 6px;
    top: 0;
    height: 60px;
  }
  #menu-trigger svg {
    width: 2.5rem;
    height: 2rem;
    fill: #ffffff;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  #menu-trigger {
    top: 1.5rem;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1440px) {
  #menu-trigger {
    top: 2.25rem;
  }
}
@media (hover: hover) {
  #menu-trigger:hover svg {
    fill: #2ca8b3;
  }
  #menu-trigger:active svg {
    fill: #ffffff;
    transition-duration: 0s;
  }
}

@media screen and (min-width: 768px) {
  .js-menu-trigger-is-inverted #menu-trigger {
    background: transparent;
  }
}
.js-menu-trigger-is-inverted .header__content--info,
.js-menu-is-active .header__content--info {
  background-color: #ffffff;
}
.js-menu-trigger-is-inverted .header__content--info a,
.js-menu-is-active .header__content--info a {
  color: #000000;
}
.js-menu-trigger-is-inverted .header__content--info address,
.js-menu-is-active .header__content--info address {
  border-color: #000000;
}
.js-menu-trigger-is-inverted #menu-trigger,
.js-menu-is-active #menu-trigger {
  outline-color: #000000 !important;
}
.js-menu-trigger-is-inverted #menu-trigger svg,
.js-menu-is-active #menu-trigger svg {
  fill: #ffffff;
}
@media (hover: hover) {
  .js-menu-trigger-is-inverted #menu-trigger:hover svg,
  .js-menu-is-active #menu-trigger:hover svg {
    fill: #2ca8b3;
  }
  .js-menu-trigger-is-inverted #menu-trigger:active svg,
  .js-menu-is-active #menu-trigger:active svg {
    fill: #000000;
  }
}

.js-menu-is-active #menu {
  transform: translateX(0);
}
.js-menu-is-active #menu > nav:first-child {
  opacity: 1;
  transition-duration: 1s;
}
.js-menu-is-active #menu aside {
  opacity: 1;
}
.js-menu-is-active #menu aside .company-info,
.js-menu-is-active #menu aside .social {
  opacity: 1;
}
.js-menu-is-active #menu-trigger {
  background: transparent;
}
.js-menu-is-active #menu-trigger svg {
  fill: #000000;
}
.js-menu-is-active #menu-trigger svg rect {
  display: none;
}
.js-menu-is-active #menu-trigger svg rect:first-child {
  transform: rotate(45deg) translateY(0.625rem);
  display: block;
}
.js-menu-is-active #menu-trigger svg rect:last-child {
  transform: rotate(-45deg) translateY(-0.625rem);
  display: block;
}
@media screen and (max-width: 1200px) {
  .js-menu-is-active #menu-trigger {
    background: #2f2f2f;
  }
  .js-menu-is-active #menu-trigger svg {
    fill: #ffffff;
  }
}

.js-viewport-frozen {
  overflow: hidden;
}
@media screen and (max-width: 767px) {
  .js-viewport-frozen .header,
  .js-viewport-frozen main,
  .js-viewport-frozen .footer,
  .js-viewport-frozen .menu-mobile-cta {
    visibility: hidden;
  }
}

.menu-mobile-cta {
  display: none;
}
@media screen and (max-width: 767px) {
  .menu-mobile-cta {
    position: fixed;
    z-index: 8;
    left: 0;
    width: 100%;
    background: rgba(44, 168, 179, 0.975);
    display: block;
    bottom: 0;
    z-index: 9999;
  }
  .menu-mobile-cta ul {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    padding: 0;
  }
  .menu-mobile-cta ul li {
    flex: 1;
  }
  .menu-mobile-cta ul li:not(:last-child) {
    border-right: 0.0625rem solid #ffffff;
  }
  .menu-mobile-cta ul a {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    color: #ffffff;
    padding: 0.5rem 1rem;
  }
  .menu-mobile-cta ul a:hover {
    background-color: #ffffff;
    color: #2ca8b3;
  }
}

.menu__procs {
  padding: 1.5rem 1rem;
  text-align: center;
}
@media screen and (min-width: 1441px) {
  .menu__procs {
    padding: 3.5rem 1rem 3.5rem;
  }
}
.menu__procs ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.menu__procs ul li {
  list-style: none;
  display: block;
  margin: 2rem 2rem;
}
@media screen and (min-width: 1441px) {
  .menu__procs ul li {
    display: inline-block;
    margin: 0 2rem;
  }
}
.menu__procs ul li a {
  font-weight: 400;
  text-transform: uppercase;
}
.menu__procs--vertical {
  padding: 0;
}
.menu__procs--vertical ul li {
  display: block;
  margin: 2rem 2rem;
}

.ruled-list {
  margin-left: 0;
  margin-bottom: 1.5rem;
  padding: 0;
  column-gap: 4rem;
  column-rule: none;
  columns: 10rem 2;
  position: relative;
  list-style: none;
}
@media screen and (max-width: 767px) {
  .ruled-list {
    columns: inherit;
  }
}
.ruled-list li {
  padding-left: 0;
  padding-top: 0.5rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  page-break-inside: avoid;
  margin-bottom: 0.75rem;
  list-style: none;
}
.ruled-list-unruled {
  list-style: disc;
  padding-left: 20px;
}
.ruled-list-unruled li {
  list-style: disc;
  padding-top: 0;
  border: 0;
}

.nav {
  padding: 0 0 0.625rem 0;
}
.nav ul {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.9375rem;
  font-weight: 400;
  letter-spacing: 0.08em;
  line-height: 1.1612903226;
  font-family: trajan-pro-3, serif;
  letter-spacing: 0;
  text-transform: capitalize;
  flex-wrap: wrap;
  text-align: center;
  margin: auto;
  padding: 1.6875rem 0;
  gap: 4.0625vw;
}
@media screen and (max-width: 767px) {
  .nav ul {
    line-height: 1.25;
  }
}
@media screen and (min-width: 1921px) {
  .nav ul {
    gap: 4.875rem;
  }
}
@media screen and (max-width: 767px) {
  .nav ul {
    flex-direction: column;
  }
}
.nav ul a {
  padding: 0.625rem;
}
.nav .hr {
  margin: 0 auto;
}

.nav-vertical ul {
  font-size: 1.9375rem;
  font-weight: 400;
  letter-spacing: 0.08em;
  line-height: 1.1612903226;
  font-family: trajan-pro-3, serif;
  letter-spacing: 0;
  text-transform: capitalize;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .nav-vertical ul {
    line-height: 1.25;
  }
}
.nav-vertical ul li {
  position: relative;
  padding: 1.125rem 0;
}
.nav-vertical ul li:not(:first-child)::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  height: 0.0625rem;
  max-width: 9.375rem;
  transform: translateX(-50%);
  background: #A2A2A2;
}

.padding-bottom-25 {
  padding-bottom: 1.3020833333vw !important;
}
@media screen and (min-width: 1921px) {
  .padding-bottom-25 {
    padding-bottom: 1.5625rem !important;
  }
}

.padding-v-30 {
  padding-top: 1.875rem !important;
  padding-bottom: 1.875rem !important;
}

.padding-v-50 {
  padding-top: 3.125rem;
  padding-bottom: 3.125rem;
}
@media screen and (max-width: 767px) {
  .padding-v-50 {
    padding-top: 24px;
    padding-bottom: 24px;
  }
}

.padding-bottom-50 {
  padding-bottom: 3.125rem;
}

.padding-66 {
  padding: 3.4375vw;
}
@media screen and (min-width: 1921px) {
  .padding-66 {
    padding: 4.125rem;
  }
}

.padding-default {
  padding: 3.9583333333vw;
}
@media screen and (max-width: 767px) {
  .padding-default {
    padding-top: 24px;
    padding-bottom: 24px;
  }
}
@media screen and (min-width: 1921px) {
  .padding-default {
    padding: 4.75rem;
  }
}

.padding-right-default {
  padding-right: 3.9583333333vw;
}
@media screen and (min-width: 1921px) {
  .padding-right-default {
    padding-right: 4.75rem;
  }
}

.padding-left-default {
  padding-left: 3.9583333333vw;
}
@media screen and (min-width: 1921px) {
  .padding-left-default {
    padding-left: 4.75rem;
  }
}

@media screen and (max-width: 767px) {
  [class^=grid-] > .padding-right-default, [class^=grid-] > .padding-left-default,
  [class*=" grid-"] > .padding-right-default,
  [class*=" grid-"] > .padding-left-default {
    padding-right: 0;
    padding-left: 0;
    width: 100%;
  }
}
.padding-top-100 {
  padding-top: 5.2083333333vw !important;
}
@media screen and (min-width: 1921px) {
  .padding-top-100 {
    padding-top: 6.25rem !important;
  }
}

.padding-bottom-100 {
  padding-bottom: 5.2083333333vw !important;
}
@media screen and (min-width: 1921px) {
  .padding-bottom-100 {
    padding-bottom: 6.25rem !important;
  }
}

.padding-v-100 {
  padding-top: 5.2083333333vw;
  padding-bottom: 5.2083333333vw;
}
@media screen and (min-width: 1921px) {
  .padding-v-100 {
    padding-top: 6.25rem;
    padding-bottom: 6.25rem;
  }
}

.padding-thick {
  padding: calc(5.2583333333vw + 1rem);
}

.padding-section-content {
  padding: 3.9583333333vw calc(5.2583333333vw + 1rem);
}
@media screen and (max-width: 767px) {
  .padding-section-content {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }
}
@media screen and (min-width: 1921px) {
  .padding-section-content {
    padding: 4.75rem 8.5625rem;
  }
}

@media screen and (max-width: 767px) {
  .padding-default > .padding-section-content:not([class^=container-border-]):not([class*=" container-border-"]):not([class^=bg-]):not([class*=" bg-"]) {
    padding: 0 !important;
  }
  img + .padding-section-content {
    margin-top: 24px;
  }
}
.padding-v-145 {
  padding-top: 7.5520833333vw;
  padding-bottom: 7.5520833333vw;
}
@media screen and (min-width: 1921px) {
  .padding-v-145 {
    padding-top: 9.0625rem;
    padding-bottom: 9.0625rem;
  }
}

.padding-v-150 {
  padding-top: 7.8125vw;
  padding-bottom: 7.8125vw;
}

.padding-top-170 {
  padding-top: 10.625rem;
}
@media screen and (max-width: 767px) {
  .padding-top-170 {
    padding-top: 50px;
  }
}

.padding-xl2 {
  padding: 11.25rem 3.9583333333vw;
}
@media screen and (min-width: 1921px) {
  .padding-xl2 {
    padding: 11.25rem 4.75rem;
  }
}

@media screen and (max-width: 1440px) {
  .padding-thick,
  .padding-section-content {
    padding: 3.9583333333vw;
  }
}
@media screen and (min-width: 1921px) {
  .padding-section-content,
  .padding-default {
    max-width: 1920px;
  }
  .padding-thick {
    padding: 8.5625rem;
  }
  .padding-v-150 {
    padding-top: 9.375rem;
    padding-bottom: 9.375rem;
  }
}
/* photo-gallery-link module: theme */ /* quote module: theme */ /* read-more-link module: theme */
@keyframes textSpin {
  from {
    transform: rotate(14deg);
  }
  to {
    transform: rotate(374deg);
  }
}
@media screen and (min-width: 768px) {
  .read-more {
    position: absolute;
    right: 0;
    bottom: 0;
  }
  .read-more,
  .read-more svg,
  .read-more .read-more__circle {
    display: block;
    transform-style: preserve-3d;
  }
  .read-more,
  .read-more .read-more__circle {
    width: 12.5rem;
    height: 12.5rem;
  }
  .read-more svg,
  .read-more .read-more__circle,
  .read-more .read-more__text,
  .read-more .read-more__arrow {
    position: absolute;
  }
  .read-more .read-more__circle {
    background: #2ca8b3;
    border-radius: 100%;
    transform: scale(0.93);
    transition: transform 0.3s ease-in;
  }
  .read-more .read-more__text {
    width: 10rem;
    height: 10rem;
    left: 1.25rem;
    top: 1.25rem;
    transform: rotate(14deg);
  }
  .read-more .read-more__arrow {
    width: 2.625rem;
    height: 1.8125rem;
    transition: transform 0.3s ease-in;
    transform: rotate(-45deg) translate(-0.3125rem, 7.25rem);
  }
  .read-more:hover .read-more__text {
    transform: none;
    animation: textSpin 8s linear infinite;
  }
  .read-more:hover .read-more__circle {
    transform: scale(1);
  }
  .read-more:hover .read-more__arrow {
    transform: rotate(-45deg) translate(0.5625rem, 7.25rem);
  }
}

@media screen and (max-width: 767px) {
  .read-more {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
    display: block;
    text-transform: uppercase;
    transition: background-color 0.3s ease-in, color 0.3s ease-in;
    padding: 1.25rem 2.25rem;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 25rem;
    color: #ffffff;
    background: #000000;
    width: auto;
    height: auto;
    margin-top: 1.25rem;
  }
  .read-more:hover {
    background: #2ca8b3;
    color: #000000;
  }
  .read-more svg,
  .read-more .read-more__circle,
  .read-more .read-more__text,
  .read-more .read-more__arrow {
    display: none !important;
  }
}
@media screen and (max-width: 767px) {
  .inverted .read-more {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
    display: block;
    text-transform: uppercase;
    transition: background-color 0.3s ease-in, color 0.3s ease-in;
    padding: 1.25rem 2.25rem;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 25rem;
    color: #000000;
    background: #2ca8b3;
  }
  .inverted .read-more:hover {
    background: #000000;
    color: #ffffff;
  }
}
@media (max-width: 1330px) {
  .riser .grid-2-col-5-7 {
    grid-template-columns: 3fr 8fr;
  }
}
@media screen and (max-width: 767px) {
  .riser .grid-2-col-5-7 {
    grid-template-columns: 1fr;
  }
}
.riser .bg-img {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 100% 50%;
  width: 50%;
  height: 100%;
  position: absolute;
  width: 60%;
}
@media screen and (max-width: 767px) {
  .riser .bg-img {
    display: none;
  }
}
.riser .bg-img-mobile {
  display: none;
}
@media screen and (max-width: 767px) {
  .riser .bg-img-mobile {
    display: block;
  }
}
.riser .galitzin-bg {
  background-image: url(/wp-content/uploads/2023/03/dr-galitzin-lg.webp);
}
.riser .artas-bg {
  background-image: url(/wp-content/uploads/2023/03/artas-device.webp);
}
.riser h2 {
  margin-bottom: 1rem;
}
.riser h3.subheader {
  font-family: franklin-gothic-urw, sans-serif;
  font-weight: 400;
  font-size: 2rem;
}
.riser .platform {
  background-color: #fff;
  background-image: url(/wp-content/themes/georgiahairinstitute_com/images/blue-bar.svg);
  background-position-x: -1.15rem;
  background-repeat: repeat-y;
  box-shadow: -15px 15px 20px 15px rgba(66, 66, 66, 0.19);
  max-width: 865px;
  padding: 6rem 6rem 6rem 10.5rem;
  position: relative;
}
@media screen and (max-width: 767px) {
  .riser .platform {
    padding: 1rem 1rem 1rem 3rem;
    background-image: none;
    box-shadow: none;
  }
}

/* social module: theme */
.social ul {
  list-style: none;
  display: flex;
  padding: 0;
}
.social ul a {
  display: block;
  padding: 0.5625rem;
}
.social ul a svg {
  display: block;
  fill: #000000;
  width: 1.375rem;
  height: 1.375rem;
  transition: fill 0.3s ease-in;
}
.social ul a:hover svg {
  fill: #2ca8b3;
}
@media screen and (max-width: 767px) {
  .social ul {
    justify-content: center;
  }
}

/* svg module: theme */
.svg__fill-accent {
  fill: #2ca8b3;
}

.svg__fill-white {
  fill: #ffffff;
}

.svg__fill-gray-dark {
  fill: #272727;
}

.width-640 {
  max-width: 40rem;
}

.width-674 {
  max-width: 42.125rem;
}

.width-812 {
  max-width: 50.75rem;
}

.width-852 {
  max-width: 53.25rem;
}

.width-1088 {
  max-width: 68rem !important;
}

.width-1128 {
  max-width: 70.5rem !important;
}

.width-1330 {
  max-width: 83.125rem !important;
}

.width-1364 {
  max-width: 85.25rem;
}

.width-1640 {
  max-width: 102.5rem;
}

.width-1920 {
  max-width: 1920px;
}

.width-full {
  max-width: 100%;
}