[data-aos="fade-in-btm"] {
  opacity: 0;
  transform: translateY($aos-slide-distance);
  transition: opacity $transition-aos-opacity,
              transform $transition-aos-transform;
}

.js-intersection-observed[data-aos="fade-in-btm"] {
  opacity: 1;
  transform: translateY(0);
}

[data-aos="fade-in"] {
  opacity: 0;
  transition: opacity $transition-aos-opacity;
}

.js-intersection-observed[data-aos="fade-in"] {
  opacity: 1;
}

[data-aos="fade-in-collage"] {
  img {
    transition: opacity $transition-aos-opacity;
    opacity: 0;
    // object-position: 0 30*$pxToRem;
    // transition: opacity $transition-aos-opacity,
    //             object-position ease 0.7s;

    &:nth-child(1) {
      transition-delay: 0.1s;
    }

    &:nth-child(2) {
      transition-delay: 0s;
    }

    &:nth-child(3) {
      transition-delay: 0.3s;
    }

    &:nth-child(4) {
      transition-delay: 0.6s;
    }

    &:nth-child(5) {
      transition-delay: 0.4s;
    }

    &:nth-child(6) {
      transition-delay: 0.2s;
    }

    &:nth-child(7) {
      transition-delay: 0.5s;
    }
  }
}

.js-intersection-observed[data-aos="fade-in-collage"] {
  img {
    opacity: 1;
    // object-position: 0 0;
  }
}

// [data-aos="fade-in-btm"] {
//   opacity: 0;
//   transform: translateY($aos-slide-distance);
//   transition: opacity $transition-aos-opacity,
//               transform $transition-aos-transform;
// }

// .js-intersection-observed[data-aos="fade-in-btm"] {
//   opacity: 1;
//   transform: translateY(0);
// }

// Delay
@for $i from 1 through 15 {
  [data-aos-delay='#{$i * 200}'] {
      transition-delay: 0s;
  }
  .js-intersection-observed[data-aos-delay='#{$i * 200}'] {
      transition-delay: #{$i * 200}ms;
  }
}

