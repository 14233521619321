/* header module: theme */

.header {
  @include util-antialiasing-default;
  //background: transparent; // Without this WAVE thinks header links are white on white which they are not
  position: absolute;
  width: 100%;
  z-index: 99;
  //box-shadow: 0 0 30px 20px rgba($color-black,.10);

  .header__content {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
    padding: 2rem 2rem;
    transition: all $transition-default;
    @include media-mobile-only {
      padding: 0;
    }
    @include media-tablet-only {
      padding: 1.4rem 2rem;
    }
    //&.bg {
    //  background-color: $color-gray-1;
    //  padding: 1rem 2rem;
    //  .header__content--column {
    //    div {
    //      .logo {
    //        max-width: 200px;
    //      }
    //    }
    //  }
    //}
    &--logo {
      @include media-above-mobile {
        display: none;
      }
      a {
        display: flex;
        padding: 0.625rem 1rem !important;
        max-height: 60px;
        width: auto;
        max-width: 190px;
        @include media-mobile-only {
          padding: 1.1rem 1rem !important;
          height: 2.5rem;
        }
      }
      //img {
      //  max-width: 100px;
      //  padding-top: 12px;
      //}
      //svg {
      //  width: 100%;
      //  max-width: 90px;
      //  @include media-mobile-only {
      //    width: 100%;
      //    max-width: 62px;
      //    height: 60px;
      //  }
      //}
    }
    &--column {
      //@include media-tablet-only {
      //  grid-template-columns: 1fr 1fr 4fr;
      //}
      //@include media-laptop-only {
      //  grid-template-columns: 1fr 1fr 4fr;
      //}
      div {
        .logo {
          max-width: 320px;
          padding: 0;
          transition: all $transition-default;
        }
        //.phone {
        //  display: flex;
        //  span {
        //    font-weight: 500;
        //    padding-left: 6px;
        //  }
        //}
        &:nth-child(1) {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-left: 2rem;
          //a {
          //  background-color: $color-accent;
          //  padding: 6*$pxToRem 36*$pxToRem;
          //  &:hover {
          //    color: $color-white;
          //  }
          //}
        }
        &:nth-child(2) {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-right: 5rem;
          color: $color-white;
          @include media-lg-monitor-only {
            margin-right: 6.5rem;
          }
          a {
            transition: all $transition-default
          }
          span {
            padding: 10*$pxToRem;
          }
        }
      }
      @include media-mobile-only {
        display: none;
      }
    }
    //&--info {
    //  font-size: 0.9rem;
    //  height: 41px;
    //  display: flex;
    //  align-items: center;
    //  justify-content: center;
    //  border: 1px solid $color-white;
    //  padding: 2px 6rem 0 1rem;
    //  position: fixed;
    //  @include media-tablet-only {
    //    padding: 2px 4rem 0 1rem!important;
    //  }
    //  @include media-below(1200) {
    //    padding: 2px 5rem 0 1rem;
    //  }
    //  p {
    //    margin: 0;
    //  }
    //  address {
    //    padding-right: 0.5rem;
    //    border-left: 1px solid $color-white;
    //    a {
    //      text-transform: capitalize;
    //      display: unset!important;
    //    }
    //  }
    //}
  }

  a:not(.header__skip) {
    color: $color-text-inverted;
    text-transform: uppercase;
    padding: 10*$pxToRem;

    &:hover {
      color: $color-accent;
    }
  }

  nav {
    ul {
      display: flex;
      gap: 80*$pxToRem;
      gap: 4.1vw;
    }

    @include media-below(1200) {
      display: none;
    }
  }

  .header__photo-gallery-link {
    position: absolute;
    text-align: center;
    bottom: 0;
    &,
    a,
    svg {
      display: block;
      width: 98*$pxToRem;
      height: 90*$pxToRem;
    }

    svg {
      fill: $color-white;
    }
  }
}

.header__skip {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $color-white;
  padding: 6*$pxToRem 20*$pxToRem;
  position: fixed;
  top: 26*$pxToRem;
  left: 48*$pxToRem;
  transform: translateX(calc(-100% - 48px));
  height: 48*$pxToRem;
  transition: transform $transition-default,
              color $transition-default;
  z-index: 7;

  &:focus {
    transform: translateX(0);
    opacity: 1;
  }

  svg {
    margin-left: 16*$pxToRem;
    width: 32*$pxToRem;
    height: 22*$pxToRem;
    transition: fill $transition-default;
  }

  &:hover {
    svg {
      fill: $color-accent;
    }
  }
}

@include media-mobile-only {
  .header {
    //@include config-fixed-menu($color-white);
    top: 0;
    height: 60px;
    z-index: 99;
    .header__content {
      min-height: 60px;
    }

    .header__left { 
      left: 0;

      .logo {
        @include util-align-children-center;
        height: 60px;
        width: auto;
        padding: 0 16px;

        svg {
          width: 190px;
          height: 40px;

          .logo__mobile {
            display: block;
          }

          .logo__desktop {
            display: none;
          }
        }
      }

      a:not(.logo) {
        display: none;
      }
    }
  }
}
