/* button module: core */
// TODO: make more logical separation between core and theme styles

// button,
// input[type="button"],
// input[type="submit"] {
//   background-color: transparent;
// }

.btn,
.button-icon {
  background-color: $bg-blue;
  width: max-content;
  display: flex;
  text-transform: capitalize;
  position: relative;
  overflow: hidden; // clip box for sliding background
  padding: 16*$pxToRem 36*$pxToRem;
  min-height: 36*$pxToRem;
  border-radius: 3px;
  //min-width: 160px;
  align-items: center;
  justify-content: center;
  z-index: 0;
  font-family: $body-font;
  color: $color-accent;
  font-weight: $body-font-bold;
  transition: all $transition-default;
  
  @include media-mobile-only {
    margin-top: 1rem!important;
    width: auto;
  }

  &:hover {
    background-color: $color-accent;
    color: $bg-blue;
    text-decoration: none;
    border-bottom: none;
  }

  border-bottom: none;
}

.button {
  // Plus sign preceding button text
  //&::before {
  //  content: "+";
  //  padding-right: 4*$pxToRem;
  //}
}

.padding-section-content .button:last-child {
  @include media-mobile-only {
    margin-bottom: $padding-mobile;
  }
}

.button-icon {
  svg {
    display: block;
    width: 16*$pxToRem;
    height: 16*$pxToRem;
    margin-right: 10*$pxToRem;
  }
}

.inverted a.btn,
.inverted a.button-icon,
a.btn.inverted,
a.button-icon.inverted {
  background-color: $color-accent;
  color: $bg-blue;
  white-space: nowrap;
  @include media-mobile-only {
    white-space: unset;
  }
  &:hover {
    background-color: $bg-blue;
    color: $color-accent;
  }
}

a.svg, .svg {
  position: relative;
  &.inverted {
    svg {
      stroke: $color-white;
    }
  }
  svg {
    position: absolute;
    top: -1.5rem;
    left: 50%;
    right: 0;
    bottom: 0;
    pointer-events: none;
    fill: none;
    stroke: $color-gray-11;
    stroke-width: 0.5px;
    margin: 0 auto;
    transform: translateX(-50%);
    path {
      stroke-dasharray: 1;
      stroke-dashoffset: 1;
      transition: stroke-dashoffset 0.4s cubic-bezier(0.7, 0, 0.3, 1);

    }
  }
  &:hover {
    svg {
      path {
        stroke-dashoffset: 0;
        transition-timing-function: cubic-bezier(0.8, 1, 0.7, 1);
        transition-duration: 0.4s;
      }
    }
  }
}

.wide {
  width: 320px;
  box-sizing: border-box;

  @include media-mobile-only {
    width: unset;
  }
}