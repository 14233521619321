body,
input,
textarea,
button,
select,
.typography-body {
  @include config-type-body;
}

h1,
h2,
.typography-heading {
  @include config-type-heading;
}

h2 {
  //span {
  //  @include config-type-subheading;
  //  display: block;
  //}
  span {
    display: block;
    text-transform: uppercase;
  }
}

h3,
.typography-subheading {
  @include config-type-subheading;
}

.typography-subheading-lg {
  @include config-type-subheading-lg;
  margin-bottom: 12*$pxToRem;
}

.typography-body-jumbo,
.accordion__content {
  font-size: $font-size-subheading*$pxToRem;
}

.typography-script {
  @include config-type-script;
}

.typography-regular-case {
  text-transform: none;
}

.typography-heading-lg {
  @include config-type-heading-lg;
}

p,
li,
td {
  a {
    font-weight: 500;
  }
}

.typography-paragraph-lg {
  font-size: 25*$pxToRem;
  line-height: 1.5;
}

strong,
b,
th,
strong a,
.typography-heavy {
  font-weight: 700;
}

small,
.typography-fine-print {
  @include config-type-fine-print;
}

// 
.typography-heading-container,
.typography-heading-container-with-script {
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: max-content;
  padding-bottom: 8*$pxToRem;

  h3 {
    order: -1;
    margin-top: $offset-subheading-micro*$pxToRem;
    margin-bottom: $offset-subheading-micro*$pxToRem;
  }

  h2 {
    margin-top: 0 !important;
    position: relative;
    z-index: 2;
  }

  h4 {
    @include config-type-script;
    position: relative;
    width: 100%;
    text-align: right;
    z-index: 1;
    margin: -16*$pxToRem 0 0 0;
  }

  & + p.typography-heavy {
    padding-right: 70*$pxToRem;

    @include media-mobile-only {
      padding-right: 0;
    }
  }
}

.typography-heading-container-with-script {
  @include media-above-mobile {
    width: 120%;

    h3,
    h2 {
      padding-right: 70*$pxToRem;
    }
  }
}

.text-align-center .typography-heading-container {
  margin: auto;

  h2 {
    width: auto;
  }
}

.color-title {
  &-light {
    color: $bg-blue;
  }
}

.title {
  font-family: $body-font;
  color: $color-black;
  font-size: 28*$pxToRem;
  font-weight: 300;
  //letter-spacing: 0.06rem;
  //text-transform: uppercase;
  margin: 0.2rem 0 0.5rem;
  @include media-above-mobile {
    font-size: 31*$pxToRem;

  }
  @include media-desktop-only {
    margin: -1.2rem 0 0.5rem;
  }
}

.uppercase {
  text-transform: uppercase;
}