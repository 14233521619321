@include media-above-mobile {
  .img-full-height {
    height: 100%;
    object-fit: cover;
  }
}

.img-gutter-pull-right {
  width: calc(100% + #{$gutter-size-16});
  margin-right: -#{$gutter-size-16};
}