/* inverted module: core */
// TODO consider "dark" or color related instead of "inverted"
// TODO perhaps this should be handled a different way in another file

.inverted {
  color: $color-text-inverted;

  a {
    color: $color-white;
    border-color: $color-white;
    border-bottom-width: 1px;
    border-bottom-style: solid;

    &:hover {
      color: $color-white;
      border-bottom: 1px solid transparent;
    }
  }


  a.flipped {
    color: $color-text-inverted;
    border-color: $color-text-inverted;
    border-bottom-width: 1px;
    border-bottom-style: solid;

    &:hover {
      color: $color-accent;
      border-bottom: none;
    }
  }

  nav,
  .nav {
    a {
      color: $color-inverted-link;

      &:hover {
        color: $color-inverted-link-hover;
      }
    }
  }

  .nav-vertical {
    li::before {
      background-color: $color-white !important;
    }
  }

  .btn {
    border-bottom: none;
  }
}

